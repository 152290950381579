
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import moment from "moment";
	import OperationFileList from "@/components/fileList/operationFileList.vue";

	interface steps {
		name:string,
		apart?:{
			completeTime:number
			description:string,
			doc:{
				filetype:string
				fileurl:URL,
				name:string,
				shortname:string
			}[],
			how?:string
			operator:string
			voice?:string
		}
	}

	@Component({
		components: { OperationFileList },
		filters: {
			getTIme (value:Number | null):string {
				if (typeof value === "number") {
					return moment(value * 1000).format("YYYY年MM月DD日");
				} else {
					return "";
				}
			}
		}
	})
		export default class OperationStep extends Vue {
		@Prop(Array) readonly step?:steps[];
		private MainLink:HTMLLinkElement = document.createElement("link");

		mounted ():void {
			this.renderCss();
		}

		@Watch("$store.state.baseColor")
		onColorChange () {
			this.renderCss();
		}

		beforeDestroy () {
			this.MainLink && this.MainLink.remove();
		}

		private renderCss () {
			less.render("@infoBlockBackground: rgba(red(@baseColor), green(@baseColor)/2, blue(@baseColor)/2, .8);@lightTitleBackground: rgba(0, green(@baseColor)/2, blue(@baseColor)/2, .6);.steps{.step{&:not(:last-child):before{border-left: 1px solid @lightTitleBackground;}.stepNumber{background-color: @lightTitleBackground;}.detail{border: 1px solid lighten(@infoBlockBackground, 30%); ul{> li:first-child:after{border-bottom: 1px solid lighten(@infoBlockBackground, 30%);} }} }}", {
				javascriptEnabled: true,
				modifyVars: {
					"@baseColor": this.$store.state.baseColor,
					"path": "/static/UnityWeb/desktop/"
				},
				paths: [ "/static/UnityWeb/desktop/" ]
			}).then(({ css }) => {
				this.MainLink && this.MainLink.remove();
				const href:string = URL.createObjectURL(new Blob([ css ]));
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
				this.MainLink = link;
			});
		}
	}

